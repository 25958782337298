<template>
  <div class="md-layout-item md-size-100">
    <md-card v-if="selectedSkill">
      <md-card-header data-background-color="blue" class="card-header">
        <div class="card-header-info">
          <h4 class="title">Изменить скилл</h4>
        </div>
      </md-card-header>

      <ValidationObserver ref="updateSkillForm" :slim="true">
        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-large-size-25">
              <md-field>
                <label>ID</label>
                <md-input disabled :value="selectedSkill.skillId" />
              </md-field>
            </div>

            <ValidationProvider
              v-slot="{ errors, reset }"
              class="md-layout-item md-large-size-75"
              mode="passive"
              tag="div"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Название</label>
                <md-input
                  v-model="selectedSkill.name"
                  :disabled="isLoaderVisible"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              class="md-layout-item md-large-size-100"
              mode="passive"
              tag="div"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Описание</label>
                <md-textarea
                  v-model="selectedSkill.description"
                  :disabled="isLoaderVisible"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <div class="md-layout-item md-large-size-20">
              <md-field>
                <label>Кулдаун</label>
                <md-input disabled :value="selectedSkill.cooldown" />
              </md-field>
            </div>

            <div class="md-layout-item md-large-size-20">
              <md-field>
                <label>Дальность</label>
                <md-input disabled :value="selectedSkill.range" />
              </md-field>
            </div>

            <div class="md-layout-item md-large-size-20">
              <md-field>
                <label>Макс.уровень</label>
                <md-input disabled :value="selectedSkill.maxSkillLevel" />
              </md-field>
            </div>

            <div class="buffs md-layout-item md-large-size-40">
              <label>Баффы</label>
              <span
                v-for="buffId in selectedSkill.buffs"
                :key="`buff_${buffId}`"
                class="link"
                @click="openBuffModal(buffId)"
              >
                {{ buffsById[buffId].name }}
              </span>
            </div>
          </div>

          <SkillLevelsTable :skill-id="selectedSkill.skillId" />

          <div class="buttons-wrap">
            <md-button
              class="md-raised md-success"
              :disabled="isLoaderVisible"
              @click="updateSkill"
            >
              Сохранить
            </md-button>

            <md-button class="md-raised md-danger" @click="cancel">
              Отмена
            </md-button>
          </div>
        </md-card-content>
      </ValidationObserver>
    </md-card>

    <BuffModal
      v-if="showModal && !!selectedBuff"
      :item="selectedBuff"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  components: {
    BuffModal: () => import("@/components/Modals/BuffModal"),
    SkillLevelsTable: () => import("@/components/Tables/SkillLevelsTable"),
  },

  data() {
    return {
      selectedBuff: null,
      selectedSkill: null,
      showModal: false,
    };
  },

  computed: {
    ...mapGetters("buffs", ["buffsById"]),
    ...mapGetters("skills", ["skillsById"]),
    ...mapState(["isLoaderVisible"]),
  },

  async mounted() {
    this.showLoader(true);

    try {
      await this.getBuffs();

      if (!this.skillsById[this.$route.params.id]) {
        await this.getSkills();
      }

      this.selectedSkill = JSON.parse(
        JSON.stringify(this.skillsById[this.$route.params.id])
      );
    } catch (error) {
      this.$router.push("/skills");
    }

    this.showLoader(false);
  },

  methods: {
    ...mapActions("buffs", ["getBuffs"]),
    ...mapActions("skills", ["getSkill", "setSkill"]),
    ...mapMutations({
      showLoader: "SET_SHOW_LOADER",
    }),

    cancel() {
      this.showLoader(true);
      this.selectedSkill = null;
      this.showLoader(false);
      this.$router.push("/skills");
    },

    closeModal() {
      this.showModal = false;
      this.selectedBuff = null;
    },

    openBuffModal(buffId) {
      this.selectedBuff = JSON.parse(JSON.stringify(this.buffsById[buffId]));
      this.showModal = true;
    },

    async updateSkill() {
      const isValid = await this.$refs.updateSkillForm.validate();
      if (!isValid) {
        return;
      }

      this.showLoader(true);

      try {
        await this.setSkill(this.selectedSkill);
        this.$router.push("/skills");
      } catch (error) {
        this.showLoader(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buffs {
  display: flex;
  flex-direction: column;

  label {
    color: #aaaaaa;
    font-size: 0.6875rem;
  }

  span {
    &:first-of-type {
      padding-top: 7px;
    }
  }
}

.link {
  color: #9c27b0;
  cursor: pointer;

  &:hover {
    color: #5a1566;
  }
}

.md-success {
  margin-right: 10px;
}
</style>